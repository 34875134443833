import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import ShoppingCartContext from '../context/shopping-cart'
import Header from './header'
import Footer from './footer'
import '../styles/layout.css'
import '../styles/overrides.css'

class Layout extends React.Component {
  constructor(props) {
    super(props)

    this.toggleShoppingCart = () => {
      this.setState({ shoppingCartOpen: !this.state.shoppingCartOpen })
    }

    this.addItemToShoppingCart = item => {
      const shoppingCart = new Map(this.state.shoppingCart)
      shoppingCart.set(item.acno, item)
      this.setState(
        {
          shoppingCart,
        },
        this.persistCartToLocalStorage
      )
    }

    this.removeItemFromShoppingCart = item => {
      const shoppingCart = new Map(this.state.shoppingCart)
      shoppingCart.delete(item.acno)
      this.setState(
        {
          shoppingCart,
          shoppingCartOpen: this.state.shoppingCartOpen
            ? shoppingCart.size > 0
            : false,
        },
        this.persistCartToLocalStorage
      )
    }

    this.clearShoppingCart = () => {
      this.setState(
        { shoppingCart: new Map(), shoppingCartOpen: false },
        this.persistCartToLocalStorage
      )
    }

    this.formatShoppingCart = () => {
      const result = []
      for (let entry of this.state.shoppingCart) {
        result.push(entry[1])
      }
      return result
    }

    this.state = {
      shoppingCart: new Map(),
      shoppingCartOpen: false,
      toggleShoppingCart: this.toggleShoppingCart,
      addItemToShoppingCart: this.addItemToShoppingCart,
      removeItemFromShoppingCart: this.removeItemFromShoppingCart,
      clearShoppingCart: this.clearShoppingCart,
      formatShoppingCart: this.formatShoppingCart,
    }
  }

  componentDidMount() {
    this.ensureCartIsMap() // TODO: remove this eventually?
    this.retrieveCartFromLocalStorage()
  }

  ensureCartIsMap() {
    const current = JSON.parse(localStorage.getItem('shoppingCart'))
    if (current && !(current instanceof Array)) {
      const shoppingCart = new Map(Object.entries(current))
      localStorage.setItem(
        'shoppingCart',
        JSON.stringify(Array.from(shoppingCart))
      )
    }
  }

  retrieveCartFromLocalStorage() {
    const shoppingCart = new Map(
      JSON.parse(localStorage.getItem('shoppingCart'))
    )
    if (shoppingCart && shoppingCart.size) {
      this.setState({
        shoppingCart,
      })
    }
  }

  persistCartToLocalStorage() {
    localStorage.setItem(
      'shoppingCart',
      JSON.stringify(Array.from(this.state.shoppingCart))
    )
  }

  render() {
    const { data, children } = this.props
    return (
      <ShoppingCartContext.Provider value={this.state}>
        <div
          style={{
            textAlign: 'center',
            backgroundColor: '#834174',
            color: '#fff',
            fontSize: '25px',
            padding: "20px 10px"
          }}
        >
        <span>Spiral Classics is now closed. Many thanks to all my customers over the last 31 years. </span> 
        </div>
        <Header
          siteTitle={data.site.siteMetadata.title}
          navigation={data.site.siteMetadata.navigation}
        />
        <div
          style={{
            position: 'relative',
            margin: '0 auto',
            maxWidth: 1200,
            padding: '2.9rem 1.0875rem',
            backgroundColor:
              typeof window !== 'undefined' &&
              window.location.pathname.includes('stock-list')
                ? 'inherit'
                : '#fff',
          }}
        >
          {children}
        </div>
        <Footer />
      </ShoppingCartContext.Provider>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default props => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            navigation {
              name
              link
            }
          }
        }
      }
    `}
    render={data => <Layout data={data} {...props} />}
  />
)
