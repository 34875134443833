import React from 'react'
import { trackEvent } from '../helpers/tracking'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import ImageGallery from 'react-image-gallery'

import one from '../images/featured/front-page-01.jpg'
import two from '../images/featured/front-page-02.jpg'
import three from '../images/featured/front-page-03.jpg'
import four from '../images/featured/front-page-04.jpg'
import five from '../images/featured/front-page-05.jpg'
import six from '../images/featured/front-page-06.jpg'
import seven from '../images/featured/front-page-07.jpg'
import eight from '../images/featured/front-page-08.jpg'
import ten from '../images/featured/front-page-10.jpg'
import eleven from '../images/featured/front-page-11.jpg'

import 'react-image-gallery/styles/css/image-gallery.css'

const images = [
  {
    original: one,
    thumbnail: one,
  },
  {
    original: two,
    thumbnail: two,
  },
  {
    original: three,
    thumbnail: three,
  },
  {
    original: four,
    thumbnail: four,
  },
  {
    original: five,
    thumbnail: five,
  },
  {
    original: six,
    thumbnail: six,
  },
  {
    original: seven,
    thumbnail: seven,
  },
  {
    original: eight,
    thumbnail: eight,
  },
  {
    original: ten,
    thumbnail: ten,
  },
  {
    original: eleven,
    thumbnail: eleven,
  },
]

function shuffle(array) {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1)); // random index from 0 to i

    // swap elements array[i] and array[j]
    // we use "destructuring assignment" syntax to achieve that
    // you'll find more details about that syntax in later chapters
    // same can be written as:
    // let t = array[i]; array[i] = array[j]; array[j] = t
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array
}

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[]} />
    <Grid container spacing={24}>
      <Grid item xs={12} sm={6} md={7}>
        <Typography variant="subtitle1">
          <p>
            I started Spiral Classics in 1994, selling carefully graded Vinyl Classical LP records, and I retired at the beginning of 2025. 
            Over the 31 years that I have been selling Classical LPs I gathered a wealth of information, which I very much wanted to share. 
            So I compiled my <Link to="/guide-to-collecting">Guide to Collecting.</Link> 
          </p>
          <p>  
            Although I have no more records to sell, I am keeping my website online, 
            so that the information on <Link to="/guide-to-collecting">Golden Age English Classical LPs</Link> continues to be available.  
          </p> 
          <p>
            Although I’m now retired you can still use the contact form at the bottom of this page if you have queries. I don’t promise to answer them, but I will if I can. And if you have a collection to sell, 
            I may be able to put you in touch with one or two of my past customers who are interested in buying some collections.
          </p>
        </Typography>
        <br />
      </Grid>
      <Grid item xs={12} sm={6} md={5}>
        <ImageGallery
          items={shuffle(images)}
          showFullscreenButton={false}
          slideDuration={800}
          slideInterval={5000}
          autoPlay
          onPlay={() =>
            trackEvent({ category: 'Home', action: 'Carousel - play' })
          }
          onPause={() =>
            trackEvent({ category: 'Home', action: 'Carousel - pause' })
          }
          onSlide={() =>
            trackEvent({ category: 'Home', action: 'Carousel - scroll' })
          }
          onThumbnailClick={(e, i) =>
            trackEvent({
              category: 'Home',
              action: 'Carousel - view image',
              label: images[i].original,
            })
          }
        />
      </Grid>
    </Grid>
  </Layout>
)

export default IndexPage
